import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { rgba } from 'polished'
import Fade from 'react-reveal/Fade'

import Theme, { Mq } from '../themes/default'
import { AppContext } from '../app-context'
import { Row, Column } from '../atoms/grid'
import { H1 } from '../atoms/heading'
import { Paragraph } from '../atoms/text'
import { Button } from '../atoms/link'
import { IContentfulHome } from '../interfaces'
import Waves from '../assets/images/waves.jpg'

const WavesContainer = styled.div`
  background-image: url(${Waves});
  background-repeat: no-repeat;
  width: 105vw;
  position: absolute;
  left: -5vw;
  top: 0;
  background-size: cover;
  z-index: -2;
  height: 100%;
`

const HeroBox = styled.div`
  position: absolute;
  z-index: -1;
  width: 80%;
  border: 10px solid ${props => rgba(props.theme.colors.lightGray, 0.5)};
  height: 76%;
  margin: 0 auto;
  top: 12%;
  left: 0;
  right: 0;
  ${Mq.md} {
    border-width: 1rem;
  }
`

const HeroParagraph = styled(Paragraph)`
  max-width: 100%;

  ${Mq.md} {
    max-width: 45rem;
  }
`

const HeroText = styled(Column)`
  ${Mq.md} {
    flex: 1;
  }
`

const HeroRow = styled(Row)`
  position: relative;
  min-height: 28rem;
  padding: 0 5%;
  overflow: hidden;

  ${Mq.md} {
    padding: 0 3%;
    margin: 0 5%;
  }

  ${Mq.xxl} {
    padding: 0 3%;
    margin: 0 15%;
  }
`

export const FooterHero = props => {
  const { appState, appDispatch } = useContext(AppContext)

  const {
    contentfulFooter,
  }: { contentfulFooter: IContentfulHome } = useStaticQuery(
    graphql`
      query FooterHeroQuery {
        contentfulFooter {
          heroTagline
          heroText
          heroCtaLink {
            ...Link
          }
        }
      }
    `,
  )

  return (
    <Fade top={true} distance={Theme.fadeDistance}>
      <HeroRow px={50}>
        <Column width={1} alignSelf="center">
          <WavesContainer />
          <HeroBox />
          <Row flexWrap="wrap" alignItems="center" py={5}>
            <HeroText width={[1, 1, 2 / 3]} mr={[0, 0, 60]} mb={[30, 30, 0]}>
              <H1>{contentfulFooter.heroTagline}</H1>
              <HeroParagraph largeText={true}>
                {contentfulFooter.heroText}
              </HeroParagraph>
            </HeroText>
            <Column>
              <Button aria-label="Go to demo request page" onClick={() => navigate('/request-demo')}>
                {contentfulFooter.heroCtaLink.title}
              </Button>
            </Column>
          </Row>
        </Column>
      </HeroRow>
    </Fade>
  )
}
