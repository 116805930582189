import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { rgba } from 'polished'
import Fade from 'react-reveal/Fade'
import SVG from 'react-inlinesvg'

import { H2, H3, H4 } from '../atoms/heading'
import { SubpageHero } from '../molecules/subpage-hero'
import { FooterHero } from '../molecules/footer-hero'
import Layout from '../organisms/layout'
import SEO from '../organisms/seo'
import Theme, { Mq } from '../themes/default'
import { Paragraph, SmallTitle } from '../atoms/text'
import { Row, Column } from '../atoms/grid'
import { UnstyledLink } from '../atoms/link'
import MicheleSignature from '../assets/images/michele-signature.svg'

const CalloutContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.lightBrown};
  margin-left: auto;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 3%;

  ${Mq.md} {
    width: 95%;
    padding-right: 8%;
  }

  ${Mq.xxl} {
    width: 85%;
    padding-right: 15%;
    margin-left: auto;
  }
`

const CalloutParagraph = styled(Paragraph)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const CalloutImage = styled(Img)`
  margin: 0 auto;
  max-height: 30rem;
  width: 75%;

  ${Mq.md} {
    max-height: 34rem;
    display: block;
    width: 95%;
  }
`

const FeaturedPeopleRow = styled(Row)`
  padding: 2rem 5% 2rem;

  ${Mq.md} {
    padding: 2rem 3% 4rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const FeaturedPeopleImg = styled(Img)`
  margin-bottom: 1.5rem;
  overflow: visible !important;

  ${Mq.sm} {
    max-width: 320px;
    margin-bottom: 3rem;
  }
  ${Mq.md} {
    &:before {
      content: '';
      position: absolute;
      border: 1rem solid ${props => rgba(props.theme.colors.darkBlue, 0.1)};
      width: 100%;
      height: 100%;
      right: -2rem;
      bottom: -2rem;
    }
  }
  ${Mq.lg} {
    max-width: 340px;
    margin-bottom: 3rem;
  }
`

const DetailedPeopleRow = styled(Row)`
  padding: 0 5% 2rem;

  ${Mq.md} {
    padding: 0 3% 5rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const DetailedPersonName = styled(H3)`
  margin-bottom: 1.2rem;
  margin-top: 0.5rem;

  &:after {
    content: '';
    display: block;
    width: 3rem;
    height: 2px;
    margin-top: 1.2rem;
    background-color: ${props => props.theme.colors.lightBlue};
  }
`

const SectionTitle = styled(H2)`
  padding: 0 5%;

  ${Mq.md} {
    padding: 0 3%;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const FeaturedPersonTitle = styled(SmallTitle)`
  margin-bottom: 1.2rem;
  margin-top: 0.5rem;

  &:after {
    content: '';
    display: block;
    width: 3rem;
    height: 2px;
    margin-top: 1.2rem;
    background-color: ${props => props.theme.colors.lightBlue};
  }
`

const FeaturedPeopleSocialLink = styled(UnstyledLink)`
  width: 4%;
  margin-right: 1rem;

  ${Mq.md} {
    width: 5%;
  }
`

const PeopleGrid = styled(Row)`
  padding: 2rem 4% 2rem;

  ${Mq.md} {
    padding: 2rem 2% 7rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const PeopleName = styled(H4)`
  margin-bottom: 0.5rem;
`

const PeopleGridImg = styled(Img)`
  margin-bottom: 1.5rem;
  max-width: 350px;
`

const People = () => {
  const { contentfulPeoplePage } = useStaticQuery(
    graphql`
      query PeopleQuery {
        contentfulPeoplePage {
          pageTitle
          pageDescription
          heroTitle
          heroText
          calloutTitle
          calloutText {
            calloutText
          }
          calloutAuthor
          calloutImage {
            fluid(maxWidth: 438) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          featuredPeople {
            name
            title
            avatar {
              fluid(maxWidth: 350) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            bio {
              bio
            }
            socialButtons {
              icon {
                file {
                  url
                }
              }
              link {
                ...Link
              }
            }
          }
          advisors {
            name
            bio
            avatar {
              fluid(maxWidth: 300) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          boardOfDirectors {
            name
            bio
            avatar {
              fluid(maxWidth: 300) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          people {
            name
            avatar {
              fluid(maxWidth: 300) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    `,
  )

  return (
    <Layout>
      <SEO description={contentfulPeoplePage.pageDescription} title={contentfulPeoplePage.pageTitle} />

      <SubpageHero
        title={contentfulPeoplePage.heroTitle}
        text={contentfulPeoplePage.heroText}
      />

      <Fade right={true} distance={Theme.fadeDistance}>
        <CalloutContainer>
          <Row flexWrap="wrap">
            <Column width={[1, 1, 2 / 5]} alignSelf="flex-end">
              <CalloutImage fluid={contentfulPeoplePage.calloutImage.fluid} alt="BiasSync CEO" />
            </Column>
            <Column width={[1, 1, 3 / 5]} pt={[4, 4, 5]} pb={[5, 4, 5]} alignSelf="center">
              <H3>{contentfulPeoplePage.calloutTitle}</H3>
              <CalloutParagraph>
                {contentfulPeoplePage.calloutText.calloutText}
              </CalloutParagraph>
              <img src={MicheleSignature} alt="Michele Ruiz signature" />
            </Column>
          </Row>
        </CalloutContainer>
      </Fade>

      <FeaturedPeopleRow flexWrap="wrap">
        {contentfulPeoplePage.featuredPeople.map((person, index: number) => (
            <Fade bottom={true} distance={Theme.fadeDistance} key={index}>
              <Row flexWrap="wrap" width={1} py={[4, 4, 5]} key={index}>
                <Column width={[1, 1, 1 / 2, 2 / 5]}>
                  <FeaturedPeopleImg
                    alt={person.name}
                    className="with-border"
                    fluid={person.avatar.fluid}
                  />
                </Column>

                <Column width={[1, 1, 1 / 2, 3 / 5]} pl={[0, 0, 5]}>
                  <Row flexDirection="row" alignItems="center">
                    <Column mr={4}>
                      <H3>{person.name}</H3>
                    </Column>

                    {person.socialButtons.map(
                      (socialButton, index: number) => {
                        return (
                          <FeaturedPeopleSocialLink
                            aria-label={socialButton.link.title}
                            key={index}
                            href={socialButton.link.externalLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <SVG
                              src={socialButton.icon.file.url}
                              className="social-icon"
                            />
                          </FeaturedPeopleSocialLink>
                        )
                      },
                    )}
                  </Row>
                  <FeaturedPersonTitle>{person.title}</FeaturedPersonTitle>
                  <Paragraph>{person.bio.bio}</Paragraph>
                </Column>
              </Row>
            </Fade>
          ))}
      </FeaturedPeopleRow>

      {process.env.GATSBY_EMPLOYEE_SECTION !== 'false' ? (
        <>
          <SectionTitle>Our Team</SectionTitle>
          <Fade cascade={true} bottom={true} distance={Theme.fadeDistance}>
            <PeopleGrid flexWrap="wrap" py={[3, 5]}>
              {contentfulPeoplePage.people.map((person, index: number) => {
                return (
                  <Column
                    width={[1 / 2, 1 / 2, 1 / 3]}
                    px={[1, 2, 3]}
                    py={3}
                    key={index}
                  >
                    <PeopleGridImg alt={person.name} fluid={person.avatar.fluid} />
                    <PeopleName>{person.name}</PeopleName>
                  </Column>
                )
              })}
            </PeopleGrid>
          </Fade>
        </>
      ) : null}

      {process.env.GATSBY_ADVISORS_SECTION !== 'false' ? (
        <>
          <SectionTitle>Advisory Board</SectionTitle>
          <DetailedPeopleRow flexWrap="wrap">
            {contentfulPeoplePage.advisors.map((person, index: number) => (
              <Column
                width={[1, 1, 1 / 2]}
                pt={[4, 4, 5]}
                pb={[3, 3, 4]}
                pr={[0, 0, 5]}
                key={index}
              >
                <Fade bottom={true} distance={Theme.fadeDistance}>
                  <PeopleGridImg alt={person.name} fluid={person.avatar.fluid} />
                  <DetailedPersonName>{person.name}</DetailedPersonName>
                  <Paragraph>{person.bio}</Paragraph>
                </Fade>
              </Column>
            ))}
          </DetailedPeopleRow>
        </>
      ) : null}

      {process.env.GATSBY_BOARD_OF_DIRECTORS_SECTION !== 'false' ? (
        <>
          <SectionTitle>Board of Directors</SectionTitle>
          <DetailedPeopleRow flexWrap="wrap">
            {contentfulPeoplePage.boardOfDirectors.map((person, index: number) => (
              <Column
                width={[1, 1, 1 / 2]}
                pt={[4, 4, 5]}
                pb={[3, 3, 4]}
                pr={[0, 0, 5]}
                key={index}
              >
                <Fade bottom={true} distance={Theme.fadeDistance}>
                  <PeopleGridImg alt={person.name} fluid={person.avatar.fluid} />
                  <DetailedPersonName>{person.name}</DetailedPersonName>
                  <Paragraph>{person.bio}</Paragraph>
                </Fade>
              </Column>
            ))}
          </DetailedPeopleRow>
        </>
      ) : null}

      <FooterHero />
    </Layout>
  )
}

export default People
